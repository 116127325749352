import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
// import Icon from '@mdi/react';
// import { mdiChevronLeft } from '@mdi/js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import classNames from 'classnames';

import { GetStartedBlock, BlueHero } from '../../Components';

import './LawyerDetail.scss';

const LawyerDetail = (props) => {

    const [isLoading, setLoading] = useState(true);
    const [lawyer, setLawyer] = useState({});
    const [isPracticeOpen, setPracticeToggle] = useState(false);
    const [isPracticeMobileOpen, setPracticeMobileToggle] = useState(false);

    const fetchLawyer = async (id) => {
        let formData = new FormData();
        formData.append("uuid", id);
        const response = await axios.post(`${process.env.REACT_APP_SITE_URL}/api/v1/marketing/lawyers`, formData);
        if (response.data.success) {
            console.log(response.data.data);
            setLawyer(response.data.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLawyer(props.match.params.id);
    }, [props.match.params.id])

    return (
        <div id="lawyer-detail">
            <BlueHero type="empty" />
            <div className="q-py-25 q-py-md-40 q-py-55-lg q-bg-whitesmoke">
                <div className="container-fluid">
                    <div className="q-mb-1_3">
                        <p className="qr-pb-md-5 mb-0">
                            <Link to="/lawyers">
                                {/* <Icon path={mdiChevronLeft} color="#220c89" size={1} /> */}
                                <FontAwesomeIcon icon={faAngleLeft} color="#220c89" />&nbsp;<span style={{ "color": "#434343" }}>Back to all lawyers</span>
                            </Link>
                        </p>
                    </div>
                    {isLoading ?
                        <div className="p-5 text-center">
                            <FontAwesomeIcon icon={faSpinner} pulse size="6x" color="#220c89" />
                        </div>
                        :
                        <div className="q-py-0 q-py-md-40 q-py-55-lg">
                            <div className="container-fluid">
                                <div className="row lawyer-profile">
                                    <div className="col-12 col-md-4">
                                        <div className="lawyer-picture">
                                            <img src={lawyer.profile_picture ? lawyer.profile_picture : require('../../Images/Qase-Default-Avatar.png')} alt="Lawyer" className="rounded-circle" />
                                        </div>
                                        <h1 className="q-color-darkslategray d-md-none">{lawyer.first_name} {lawyer.last_name}</h1>
                                        {
                                            lawyer.practice_start_date &&
                                            <div className="lawyer-exp q-mb-20">
                                                {lawyer.practice_start_date} of experience
                                                </div>
                                        }

                                        {
                                            lawyer.practice_areas &&
                                            <div className="lawyer-practice-areas mb-3">
                                                {
                                                    lawyer.practice_areas.map(area => {
                                                        return <div key={area.item_id} className="badge lawyer-badge">{area.name}</div>
                                                    })
                                                }
                                            </div>
                                        }

                                    </div>
                                    <div className="col-12 col-md-8 qr-mt-3">
                                        <h1 className="q-color-darkslategray d-none d-md-block">{lawyer.first_name} {lawyer.last_name}</h1>
                                        {lawyer.introduction &&
                                            <p>
                                                {lawyer.introduction}
                                            </p>
                                        }
                                        {lawyer.practice_areas &&
                                            <div className="practices-wrapper d-none d-md-inline-block">
                                                <button className="btn btn-melon btn-block" onClick={() => setPracticeToggle(!isPracticeOpen)}>{`Book a consult with ${lawyer.first_name}`}</button>
                                                <div className={classNames("list-group list-group-flush text-center", { 'd-none': !isPracticeOpen })}>
                                                    <div className="list-group-item q-bg-whitesmoke list-title"><small>Choose a Practice Area</small></div>
                                                    {
                                                        lawyer.practice_areas.map((area, index) => {
                                                            return <a
                                                                key={area.item_id}
                                                                href={`${process.env.REACT_APP_SITE_URL}?newcase=true&lawyer=${lawyer.uuid}&practice_jurisdiction=${area.field_locale}&practice_area=${area.field_lawyer_practice_area}`}
                                                                className={classNames("list-group-item list-group-item-action q-practice-list", { 'q-brd-top-0': index === 0 })
                                                                }>
                                                                {area.name
                                                                }</a>
                                                        })
                                                    }
                                                    <button type="button" className="list-group-item list-group-item-action q-practice-action" onClick={() => setPracticeToggle(!isPracticeOpen)}>cancel</button>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>


                                {lawyer.practice_areas &&
                                    <div className={classNames("practices-wrapper-mobile d-block d-md-none fixed-bottom", { 'open': isPracticeMobileOpen })}>
                                        <button className="btn btn-melon" onClick={() => setPracticeMobileToggle(!isPracticeMobileOpen)}>{`Book a consult with ${lawyer.first_name}`}</button>
                                        <div className={classNames("list-group list-group-flush text-center", { 'd-none': !isPracticeMobileOpen })}>
                                            <div className="list-group-item q-bg-whitesmoke list-title"><small>Choose a Practice Area</small></div>
                                            {
                                                lawyer.practice_areas.map((area, index) => {
                                                    return <a
                                                        key={area.item_id}
                                                        href={`${process.env.REACT_APP_SITE_URL}?newcase=true&lawyer=${lawyer.uuid}&practice_jurisdiction=${area.field_locale}&practice_area=${area.field_lawyer_practice_area}`}
                                                        className={classNames("list-group-item list-group-item-action q-practice-list", { 'q-brd-top-0': index === 0 })
                                                        }>
                                                        {area.name
                                                        }</a>
                                                })
                                            }
                                            <button type="button" className="list-group-item list-group-item-action q-practice-action" onClick={() => setPracticeMobileToggle(!isPracticeMobileOpen)}>cancel</button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    }

                </div>
            </div>
            <GetStartedBlock />
        </div >
    )
};

export default LawyerDetail;
