import React, { Fragment } from 'react';

import { WhiteChartBlock, LawyerInformationBlock, BlueHero, Testimonials } from '../../Components';

import './Home.scss';


const Home = () => {
    return (
        <Fragment>
             <BlueHero
                title="We’ve made it easy to land more clients."
                subtitle="Qase Convert enables instant lead intake and booking from your firm website."
                // descriptions={[
                //     "Qase Convert enables instant lead intake and booking from your firm website.",
                // ]}
                image_src={require("../../Images/Qase_online_video_consult.png")}
                wave="wave-white"
            />
<div id="page-qase-convert">
            <section>
                    <div className="position-relative overflow-hidden">
                        <div className="divider divider-bottom wave-gray reverse">
                            <svg
                                width="102%"
                                clipRule="evenodd"
                                fillRule="evenodd"
                                strokeLinejoin="round"
                                strokeMiterlimit="2"
                                viewBox="0 0 4992 596"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                    fill="#fff"
                                    className="mono"
                                ></path>
                            </svg>
                        </div>
                        <div className="q-py-55">
                            <div className="container">
                                <div className="q-mb-1_3 q-mb-lg-100 q-mb-md-66">
                                    <div>
                                        <h2 className="text-center q-mb-15">
                                            Qase Convert’s cloud-based software
                                            is everything you need in one place.
                                            Meet the needs of modern clients and
                                            scale your practice.
                                        </h2>
                                        <h2 className="text-center">
                                            It’s fast and easy to get started.
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="container-xl">
                                <div className="row q-mb-lg-100 q-mb-md-66 q-mb-20">
                                    <div className="col-md-6">
                                        <div className="d-flex h-100 align-items-center">
                                            <div>
                                                <p>
                                                    <img
                                                        src={require("../../Images/QaseConvert/trending_up_black_24dp.svg")}
                                                        className="q-mr-8"
                                                    />
                                                    GROW
                                                </p>
                                                <h3>Never miss a new lead</h3>
                                                <p>
                                                    Through online booking 24-7
                                                    or calling, your leads can
                                                    seamlessly make new
                                                    appointment requests. Review
                                                    and respond quickly and
                                                    filter out tire kickers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-center">
                                            <img
                                                src={require("../../Images/QaseConvert/Group15.png")}
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row q-mb-lg-100 q-mb-md-66 q-mb-20">
                                    <div className="col-md-6 order-md-12">
                                        <div className="d-flex h-100 align-items-center">
                                            <div>
                                                <p>
                                                    <img
                                                        src={require("../../Images/QaseConvert/category_black_24dp.svg")}
                                                        className="q-mr-8"
                                                    />
                                                    ALL IN ONE
                                                </p>
                                                <h3>
                                                    Meet your clients where they
                                                    are
                                                </h3>
                                                <p>
                                                    Discerning clients want to
                                                    engage online for legal
                                                    services. Client’s can share
                                                    documents, view notes and
                                                    tasks, receive invoices and
                                                    pay for services through a
                                                    sleek client portal.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 order-md-1">
                                        <div className="text-center">
                                            <img
                                                src={require("../../Images/QaseConvert/Group14.png")}
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row q-mb-lg-100 q-mb-md-66 q-mb-20">
                                    <div className="col-md-6">
                                        <div className="d-flex h-100 align-items-center">
                                            <div>
                                                <p>
                                                    <img
                                                        src={require("../../Images/QaseConvert/done_black_24dp.svg")}
                                                        className="q-mr-8"
                                                    />
                                                    EASY
                                                </p>
                                                <h3>Start Now</h3>
                                                <p>
                                                    Your success depends on
                                                    efficient firm operations.
                                                    Join Qase to quickly start
                                                    automating services. Don’t
                                                    loose another billable
                                                    minute to overly complicated
                                                    practice management systems.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-center">
                                            <img
                                                src={require("../../Images/QaseConvert/Group13.png")}
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
         
         	<section id="convenience-speed">
					<div className="convenience-speed-wrapper q-bg-whitesmoke position-relative">
						<div className="divider divider-bottom wave flip-y">
							<svg
								width="102%"
								clipRule="evenodd"
								fillRule="evenodd"
								strokeLinejoin="round"
								strokeMiterlimit="2"
								viewBox="0 0 4992 596"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
									fill="#fff"
									className="mono"
								></path>
							</svg>
						</div>

						<div className="q-py-65 q-py-md-80 q-py-100-lg">
							<div className="container">
								<div className="row justify-content-start align-content-center">
									<div
										className="col-12 col-md-12 col-lg-4 order-1 order-lg-2 q-mb-1_3 mb-lg-0 marginTop"
										style={{
											marginTop: "33px",
											marginBottom: "22px!important",
										}}
									>
										<div className="blue-block q-bg-midnightblue qr-p-3">
											<div className="q-mb-1_3">
												<div>
													<p className="qr-my-3 qr-mb-md-4">
														Qase and other trusted
														research found clients
														are looking for:
													</p>
													<h3 className="q-color-white q-mb-1_3 qr-mb-lg-4 q-px-md-3 q-px-lg-0 d-md-inline-block d-lg-block text-align-left">
														RESPONSIVENESS
													</h3>
													<h3 className="q-color-white q-mb-1_3 qr-mb-lg-4 q-px-md-3 q-px-lg-0 d-md-inline-block d-lg-block text-align-left">
														CONVENIENCE
													</h3>
													<h3 className="q-color-white mb-0 q-px-md-3 q-px-lg-0 d-md-inline-block d-lg-block text-align-left">
														SPEED
													</h3>
												</div>
											</div>
										</div>
									</div>
									<div className="col-12 col-md-6 col-lg-4 order-2 order-lg-1">
										<h3 className="q-color-midnightblue text-center">
											What clients want:
										</h3>

										<div className="q-mb-1_3">
											<WhiteChartBlock
												description="of clients hire the first lawyer they talk to."
												image_url={require("../../Images/QaseConvert/57.svg")}
											/>
										</div>

										<div className="q-mb-1_3">
											<WhiteChartBlock
												description="of clients expect lawyers to get back to them within 24 hours."
												image_url={require("../../Images/QaseConvert/79.svg")}
											/>
										</div>
										<div className="q-mb-1_3 qr-mb-md-0">
											<WhiteChartBlock
												description="of leads request an initial consultation on the lawyer's first day of availability."
												image_url={require("../../Images/QaseConvert/94.svg")}
											/>
										</div>
									</div>
									<div className="col-12 col-md-6 col-lg-4 order-3 order-lg-3">
										<h3 className="q-color-midnightblue text-center">
											What lawyers deliver:
										</h3>
										<div className="q-mb-1_3">
											<WhiteChartBlock
												description="of lawyers do not respond to email outreach from leads."
												image_url={require("../../Images/QaseConvert/60.svg")}
											/>
										</div>

										<div className="q-mb-1_3">
											<WhiteChartBlock
												description="do not answer the phone or return voicemails from leads."
												image_url={require("../../Images/QaseConvert/27.svg")}
											/>
										</div>

										<div className="q-mb-1_3">
											<WhiteChartBlock
												description="of lawyers currently allow leads to book directly from their websites."
												image_url={require("../../Images/QaseConvert/1.svg")}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Join Case */}
			<section id="join-case">
					<div className="join-case-wrapper q-bg-midnightblue position-relative overflow-hidden">
						<div className="q-py-65 q-py-md-80 q-py-100-lg">
							<div className="divider divider-bottom wave-gray reverse">
								<svg
									width="102%"
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 4992 596"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
										fill="#fff"
										className="mono"
									></path>
								</svg>
							</div>
							<div className="container">
								<div className="q-mb-1_3 overflow-hidden">
									<div className="q-mb-1_3">
										<div>
											<h2 className="text-center text-white">
												Use Qase and watch your business
												grow
											</h2>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-6">
										<LawyerInformationBlock
											title="Lower barriers"
											description="Clients tentatively book based on lawyers’ actual availability."
											image_url={require("../../Images/QaseConvert/Lower barriers.svg")}
										/>
									</div>
									<div className="col-12 col-md-6">
										<LawyerInformationBlock
											title="Qualify your lead"
											description="Review leads at a glance and accept only the right, conflict-free leads."
											image_url={require("../../Images/QaseConvert/Qualify your lead.svg")}
										/>
									</div>
									<div className="col-12 col-md-6">
										<LawyerInformationBlock
											title="Lower administration"
											description="Cut the back-and-forth needed to book new client appointments."
											image_url={require("../../Images/QaseConvert/Lower administration.svg")}
										/>
									</div>
									<div className="col-12 col-md-6">
										<LawyerInformationBlock
											title="Convert more leads"
											description="Make your advertising dollars and hard-earned word-of-mouth count."
											image_url={require("../../Images/QaseConvert/Convert more leads.svg")}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

			<section id="what-is-saying">
					<div className="q-bg-whitesmoke q-py-100 q-pt-md-38 q-pb-md-72 position-relative">
						{/* <div class="divider divider-bottom wave-white reverse">
                            <svg
                                width="102%"
                                clipRule="evenodd"
                                fillRule="evenodd"
                                strokeLinejoin="round"
                                strokeMiterlimit="2"
                                viewBox="0 0 4992 596"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
                                    fill="#fff"
                                    class="mono"
                                ></path>
                            </svg>
                        </div> */}
						<div className="container-xl">
							<h1 className="text-center q-color-midnightblue q-mb-32 q-mb-md-40">
								What Our Community is Saying…
							</h1>

							<div className="row">
								<div className="col-md-4">
									<Testimonials
										text="I’m so grateful for your service, the lawyer who attended to me was amazing. He really took the time and explained the case and the probabilities."
										name="Employment Law Client"
									/>
								</div>
								<div className="col-md-4">
									<Testimonials
										text="Clear concise and detailed answers. Excellent interaction."
										name="Small Business Client"
									/>
								</div>
								<div className="col-md-4">
									<Testimonials
										text="My lawyer treated me very professionally and was very considerate."
										name="Family Law Client"
										is_revers={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</section>

			<section>
					<div className="q-py-25 q-py-md-40 q-py-55-lg position-relative">
						<div className="divider divider-top wave-gray flip-x">
							<svg
								width="102%"
								clipRule="evenodd"
								fillRule="evenodd"
								strokeLinejoin="round"
								strokeMiterlimit="2"
								viewBox="0 0 4992 596"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z"
									fill="#fff"
									className="mono"
								></path>
							</svg>
						</div>
						<div className="container">
							{/* <div className="qr-py-3">
                                <div className="q-mb-1_1 text-center">
                                    <h2 className="q-color-midnightblue">Interested, what’s next?</h2>
                                </div>
                            </div> */}

							<div className="row">
								<div className="col-12 col-md-12">
									<div className="qr-p-3 qr-p-md-5">
										<div className="q-mb-1_3 text-center">
											<h2 className="">
												Interested, what’s next?
											</h2>
											<br></br>
											<p className="q-mb-1_5 p2">
												Contact us now for a demo and to
												learn more <br /> about trying
												Qase for free.
											</p>
											<div className="text-center">
												<a
													className="btn btn-melon btn-lg"
													href="mailto:support@qase.us"
												>
													Contact Us
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
        </div>
        </Fragment>
    )
};


export default Home;