import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { axios } from '../../Core'

import { GetStartedBlock, BlueHero, PracticeAreaBlock } from '../../Components'
import { getPracticeAreaLink } from '../../Utils/Helpers'

const PracticeAreas = () => {

    const [isLoading, setLoading] = useState(true);
    const [practiceAreas, setPracticeAreas] = useState([]);


    useEffect(() => {
        axios.post('/api/v1/marketing/practice_areas')
            .then(res => {
                if (res.data.success) {
                    //console.log(res.data.data);
                    setPracticeAreas(res.data.data);
                    setLoading(false);
                }
            })
    }, []);

    const getArea = (pr_area) => {
        //console.log(pr_area);
        if (pr_area.body === false) {
            return pr_area.name;
        } else {
            return (
                <u>
                    <Link to={getPracticeAreaLink(pr_area)}>{pr_area.name}</Link>
                </u>
            )
        }

    };

    return (
        <div>
            <BlueHero
                title="Get legal help"
                descriptions={[
                    "At Qase, you can meet with a lawyer to discuss your legal options and figure out how a lawyer can work with you to solve your problem. You will learn about the process, likely timelines and costs.",
                    "Interested in a particular area of law? Check out the practice areas, below."
                ]}
                image_src={require('../../Images/Qase_online_collaboration_tools_0.png')}
            />

            <Fragment>
                <div className="q-py-25 q-py-md-40 q-py-55-lg q-bg-whitesmoke">
                    <div className="container-fluid">
                        <div className="row">
                            {isLoading ?
                                <div className="col-12 p-5 text-center">
                                    <FontAwesomeIcon icon={faSpinner} pulse size="6x" color="#220c89" />
                                </div>
                                :
                                <div className="col-12 col-md-8">
                                    <div className="q-mb-20">
                                        <h3 className="q-color-midnightblue q-line-height1 q-ff-bold">Lawyers currently offer advice in the following areas:</h3>
                                        {practiceAreas &&
                                            <ul className="qr-pl-1_5">
                                                {
                                                    practiceAreas.map(pr_area => {
                                                        return (<li key={pr_area.id}>
                                                            {getArea(pr_area)}
                                                        </li>);
                                                    })
                                                }
                                            </ul>
                                        }
                                        <p>Qase is growing. If you don’t see your legal issue, check back soon. You can also learn more about some practice areas below.</p>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>

                {!isLoading &&
                    <div className="qr-py-3 q-bg-whitesmoke">
                        <div className="container-fluid">
                            <div className="row">
                                {practiceAreas &&
                                    practiceAreas.filter((item) => {
                                        return item.body !== false;
                                    }).map(pr_area => {
                                        return (<div className="col-12 col-md-6 q-mb-1_3" key={pr_area.id}>
                                            <PracticeAreaBlock
                                                title={pr_area.name}
                                                description={pr_area.description}
                                                link_url={getPracticeAreaLink(pr_area)}
                                            />
                                        </div>);
                                    })
                                }
                            </div>
                        </div>
                    </div>
                }

            </Fragment>

            <GetStartedBlock />
        </div>
    )
}

export default PracticeAreas;