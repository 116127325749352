import React, { useState } from 'react';
import classNames from 'classnames';
import { useForm } from "react-hook-form";
import { axios } from '../../Core'

import "./GetStartedBlockUS.scss";

const GetStartedBlockUS = () => {

    const [activeStep, setActiveStep] = useState(0);
    const [isFormSubmited, setIsFormSubmited] = useState(false);
    const { register, handleSubmit, errors, reset } = useForm();

    const onSubmit = (data, e) => {
        setIsFormSubmited(true);
        let formData = new FormData();
        for (let [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }
        axios.post('/api/v1/marketing/us-signup', formData)
            .then(result => {
                setIsFormSubmited(false);
                reset();
                setActiveStep(activeStep + 1);
            });
    };

    return (
        <section id="get-started" className="q-bg-midnightblue">
            <div className="get-started-wrapper position-relative">
                <div className="divider divider-top wave-gray flip-x">
                    <svg width="102%" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 4992 596" xmlns="http://www.w3.org/2000/svg">
                        <path d="m-4.167 88.792c177.084-45.221 336.804-67.83 479.167-67.83 213.542 0 765.625 88.7 989.583 118.267 223.959 29.567 380.529 32.571 545.138 26.088 309.029-12.175 482.642-60.871 772.571-76.525 142.4-7.688 502.116 9.779 883.679 90.437 381.562 80.659 701.391 201.75 888.891 219.142 125 11.592 271.988 5.796 440.971-17.392v-389.312h-5000v97.125z" fill="#fff" className="mono"></path>
                    </svg>
                </div>
                <div className="q-py-25 q-py-md-40 q-py-55-lg">
                    <div className="container-fluid">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-12 col-lg-8 qr-pt-4">
                                <div className="q-mb-1_3 q-color-white text-center">
                                    <h2 className="q-color-white q-line-height1 q-ff-medium">Qase is growing.</h2>
                                    <p className="mb-0">Sign up to be notified when Qase becomes available in your area.</p>
                                </div>
                                <div className="q-mb-1_3">
                                    <div className="d-flex">

                                        <div className="mx-auto">
                                            {(() => {
                                                switch (activeStep) {
                                                    case 0:
                                                    default:
                                                        return (
                                                            <div className="form-inline">
                                                                <div className="form-group">
                                                                    <button onClick={() => setActiveStep(activeStep + 1)} className="btn btn-melon">Sign Up Now</button>
                                                                </div>
                                                            </div>
                                                        );
                                                    case 1:
                                                        return (
                                                            <div className="signup-card-wrapper" style={{ maxWidth: "350px" }}>
                                                                <div className="d-flex justify-content-around align-items-center flex-column">

                                                                    <button type="button" className="close exit-sginup" onClick={() => setActiveStep(activeStep - 1)}>
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                    <div className="col-12">
                                                                        <h2 className="signup-card-header q-color-darkslategray">Sign up</h2>
                                                                        <h3 className="signup-card-subheader">We'll let you know when Qase launches near you.</h3>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                                            <div className="form-group">
                                                                                <input className={classNames("form-control", { "is-invalid": errors.firstName })} autoComplete="chrome-off" type="text" placeholder="First Name" name="firstName" ref={register({ required: true })} />
                                                                                <div className="invalid-feedback">
                                                                                    First name is required
                                                                                    </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <input className={classNames("form-control", { "is-invalid": errors.lastName })} autoComplete="chrome-off" type="text" placeholder="Last Name" name="lastName" ref={register({ required: true })} />
                                                                                <div className="invalid-feedback">
                                                                                    Last name is required
                                                                                    </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <input className={classNames("form-control", { "is-invalid": errors.emailAddress })} autoComplete="chrome-off" type="email" placeholder="Email Address" name="emailAddress" ref={register({ required: true, pattern: /^\S+@\S+$/i })} />
                                                                                {errors.emailAddress &&
                                                                                    <div className="invalid-feedback">
                                                                                        {errors.emailAddress.type === "required" ? "Email is required" : "Please enter valid email"}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <select className={classNames("form-control", { "is-invalid": errors.interests })} name="interests" ref={register({ required: true })}>
                                                                                    <option value="">I am interested in...</option>
                                                                                    <option value="Receiving legal services">Receiving legal services</option>
                                                                                    <option value="Providing legal services">Providing legal services</option>
                                                                                    <option value="Learning more about Qase">Learning more about Qase</option>

                                                                                </select>
                                                                                <div className="invalid-feedback">
                                                                                    Field is required
                                                                                    </div>
                                                                            </div>
                                                                            <button className="btn btn-melon btn-block" disabled={isFormSubmited}>Submit</button>
                                                                        </form>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        );
                                                    case 2:
                                                        return (
                                                            <div className="signup-card-wrapper" style={{ maxWidth: "350px" }}>
                                                                <div className="d-flex justify-content-around align-items-center flex-column">
                                                                    <button type="button" className="close exit-sginup" onClick={() => setActiveStep(0)}>
                                                                        <span aria-hidden="true">×</span>
                                                                    </button>
                                                                    <div className="col-12">
                                                                        <h2 className="signup-card-header q-color-darkslategray">Sign up</h2>
                                                                        <h3 className="signup-card-subheader">We'll let you know when Qase launches near you.</h3>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="q-mb-1_3">
                                                                            <img className="img-centered-half" src={require("../../Images/Qase_online_collaboration_tools_0.png")} alt="video-chat" />
                                                                        </div>

                                                                        <p className="mb-0">
                                                                            Thanks for your interest! Stay tuned for more information!
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                }
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default GetStartedBlockUS;