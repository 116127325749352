import React from "react";
import classnames from "classnames";

import userIcon from "../../Images/noun_user-svg.webp";

import "./Testimonials.scss";

const Testimonials = ({ text, name, is_revers }) => {
    return (
        <div
            className={classnames("card testimonials-card", {
                "card-revers": is_revers,
            })}
        >
            <div className="card-body text-center">
                <p className="p2 m-0">{text}</p>
            </div>
            <div className="card-footer">
                <div className="d-flex justify-content-center align-items-center">
                    <div>
                        <img
                            className="mr-2"
                            src={userIcon}
                            alt="User"
                            width="40"
                            height="40"
                        />
                    </div>
                    <div>
                        <p className="p2 q-color-midnightblue f-bold m-0">
                            {name}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
