import React from 'react'
import { Link } from 'react-router-dom'

import './PracticeAreaBlock.scss'

const PracticeAreaBlock = ({ title, description, description_format, link_url }) => {

    const getDescription = () => {
        if (description) {
            if (description_format === "plain_text") {
                return (<div className="practice-block__intro">
                    <p>{description}</p>
                </div>);
            }

            return (
                <div className="practice-block__intro" dangerouslySetInnerHTML={{ __html: description }}></div>
            )
        }
        return null;
    }

    return (
        <div className="card practice-block">
            <div className="card-body">
                <h2 className="text-center">
                    {title &&
                        <Link to={link_url ? link_url : ""}>{title}</Link>
                    }

                </h2>

                {getDescription()}

                {link_url &&
                    <Link className="btn btn-melon btn-block" to={link_url}>Read more</Link>
                }

            </div>
        </div>
    )
}

export default PracticeAreaBlock;