import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

import "./ContactBlock.scss"

const getIcon = icon_type => {
    switch (icon_type) {
        case 'email':
            return <FontAwesomeIcon icon={faEnvelope} color="#220c89" className="header-icon" />
        case 'phone':
            return <FontAwesomeIcon icon={faPhone} color="#220c89" className="header-icon" />
        case 'question':
            return <FontAwesomeIcon icon={faQuestionCircle} color="#220c89" className="header-icon" />
        default:
            return null;
    }
};


const ContactBlock = ({ title, iconType, description, contact, isEmail = false }) => {

    return (
        <div className="card contact-block q-mb-1_3">
            <div className="card-body">
                <h3 className="q-color-midnightblue q-ff-medium">
                    {getIcon(iconType)}
                    {title}
                </h3>
                <p className="q-mb-1_5">
                    {description}
                </p>
                <p className="mb-0">
                    <strong className="q-ff-bold">
                        {isEmail ?
                            <a href={`mailto:${contact}`}>{contact}</a>
                            :
                            contact
                        }

                    </strong>
                </p>
            </div>
        </div>
    )
}

export default ContactBlock;
