import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
// import Icon from '@mdi/react';
// import { mdiChevronLeft } from '@mdi/js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faAngleLeft } from '@fortawesome/free-solid-svg-icons'

import { axios } from '../../Core'
import { GetStartedBlock, BlueHero, BookConsultCard, LawyerBlock, PracticeAreaBlock } from '../../Components';

import "./PracticeArea.scss";
import { getPracticeAreaLink } from '../../Utils/Helpers';


const PracticeArea = (props) => {

    const [isLoading, setLoading] = useState(true);
    const [practiceArea, setPracticeArea] = useState({});
    const fetchPracticeArea = async (id) => {
        // let name = id.replace("_", " ").replace("-", " ");
        let name = id.split("_").join(" ");
        let formData = new FormData();
        formData.append("area_name", name);
        const response = await axios.post('/api/v1/marketing/practice_areas', formData);
        if (response.data.success) {
            //console.log(response.data);
            setLoading(false);
            setPracticeArea(response.data.data);
        }
    };

    useEffect(() => {
        fetchPracticeArea(props.match.params.id);
    }, [props.match.params.id]);

    return (
        <div className="q-bg-whitesmoke">
            <BlueHero type="area-detail" title={practiceArea.name} />
            <div className="container-fluid">
                <div className="qr-pb-md-3">
                    <p className="mb-0">
                        <Link to="/practice-areas">
                            {/* <Icon path={mdiChevronLeft} color="#220c89" size={1} /> */}
                            <FontAwesomeIcon icon={faAngleLeft} color="#220c89" />
                            &nbsp;See all Practice Areas
                    </Link>
                    </p>
                </div>
            </div>

            <div className="container-fluid">
                {isLoading ?
                    <div className="p-5 text-center">
                        <FontAwesomeIcon icon={faSpinner} pulse size="6x" color="#220c89" />
                    </div> :

                    <div className="qr-py-3 qr-py-md-4 qr-py-lg-5">
                        <div className="row">
                            <div className="col-12 col-md-7 col-lg-8" id="practice_area">
                                {practiceArea.body &&
                                    <div className="" dangerouslySetInnerHTML={{ __html: practiceArea.body }}></div>
                                }
                            </div>
                            <div className="col-12 col-md-5 col-lg-4">
                                <BookConsultCard />
                            </div>
                        </div>
                    </div>


                }
            </div>


            {(practiceArea.has_children && practiceArea.children.length > 0) &&
                <div className="container-fluid">
                    <div className="row">
                        {
                            practiceArea.children.map(pr_area => {
                                return (<div className="col-12 col-md-6 q-mb-20" key={pr_area.id}>
                                    <PracticeAreaBlock
                                        title={pr_area.name}
                                        description={pr_area.description}
                                        link_url={getPracticeAreaLink(pr_area)}
                                    />
                                </div>);
                            })
                        }
                    </div>
                </div>
            }

            {(practiceArea.show_lawyers && practiceArea.lawyers.length > 0) &&
                <div className="container-fluid">
                    <h3>Lawyers specialized in {practiceArea.name}</h3>
                    <div className="row">
                        {

                            practiceArea.lawyers.map(lawyer => {
                                return (
                                    <div key={lawyer.uuid} className="col-sm-6 col-md-6 col-lg-4 qr-my-3">
                                        <LawyerBlock
                                            full_name={`${lawyer.first_name} ${lawyer.last_name}`}
                                            areas={lawyer.practice_areas}
                                            profile_picture={lawyer.profile_picture}
                                            link_url={`/lawyer/${lawyer.uuid}`}
                                            practice_start={lawyer.practice_start_date}
                                        />
                                    </div>
                                )
                            })

                        }
                    </div>
                </div>
            }

            <GetStartedBlock />
        </div>
    )
}

export default PracticeArea;
