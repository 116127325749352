import React from "react";
import { Link } from "react-router-dom";
import Icon from "@mdi/react";
import {
    mdiMapMarkerOutline,
    mdiEmailOutline,
    mdiInstagram,
    mdiYoutube,
    mdiLinkedin,
    mdiTwitter,
    mdiFacebook,
} from "@mdi/js";

import "./Footer.scss";

const Footer = () => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 q-mb-1_95">
                        <div className="footer-logo">
                            <img
                                src={require("../../Images/Qase-Logo-White.svg")}
                                alt="Qase"
                            />
                        </div>
                        <div className="q-py-5">
                            <div className="media">
                                <Icon
                                    path={mdiMapMarkerOutline}
                                    color="white"
                                    size={1}
                                    style={{ marginRight: "11px" }}
                                />
                                <div className="media-body">
                                    <p className="mb-0">
                                        701 - 33 Water Street
                                    </p>
                                    <p className="mb-0">
                                        Vancouver, BC V6B 1R4
                                    </p>
                                    <p className="mb-0">(844) 435-7658</p>
                                </div>
                            </div>
                        </div>

                        <div className="q-py-5">
                            <div className="media">
                                <Icon
                                    path={mdiEmailOutline}
                                    color="white"
                                    size={1}
                                    className="q-mr-10"
                                />
                                <div className="media-body">
                                    <span className="mb-0">
                                        support@qase.us
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="social-button">
                            <a
                                href="https://www.instagram.com/qasecanada/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Icon path={mdiInstagram} color="white" />
                                {/* <i className="mdi mdi-instagram"></i> */}
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCQm1BCHrTTD62pyeTFvY4HQ"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Icon path={mdiYoutube} color="white" />
                                {/* <i className="mdi mdi-youtube"></i> */}
                            </a>
                            <a
                                href="https://www.linkedin.com/company/qaseinc/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Icon path={mdiLinkedin} color="white" />
                                {/* <i className="mdi mdi-linkedin"></i> */}
                            </a>
                            <a
                                href="https://twitter.com/QaseCanada?lang=en"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Icon path={mdiTwitter} color="white" />
                                {/* <i className="mdi mdi-twitter"></i> */}
                            </a>
                            <a
                                href="https://www.facebook.com/qasecanada/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Icon path={mdiFacebook} color="white" />
                                {/* <i className="mdi mdi-facebook"></i> */}
                            </a>
                        </div>
                    </div>
                    {/* <div className="col-md-8 q-mb-1_95">
                        <div className="row justify-content-start">
                            <div className="col-auto">
                                <ul className="list-unstyled footer-menu">
                                    <li className="list-header">
                                        <span>Resources</span>
                                    </li>
                                    <li>
                                        <Link to="/practice-areas">Practice Areas</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-auto">
                                <ul className="list-unstyled footer-menu">
                                    <li>
                                        <Link to="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div> */}
                </div>
                <div className="row">
                    <div className="col">
                        <p className="q-mb-1_5">
                            Proudly made in Vancouver, British Columbia, Canada
                        </p>
                    </div>
                    <div className="col">
                        <p className="text-right q-mb-1_5">
                            <Link to="/privacy_policy">Privacy</Link>
                            &nbsp;|&nbsp;<Link to="/terms">Terms</Link>
                            |&nbsp;
                            <Link to="/qase-convert-terms">SaaS Terms</Link>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
