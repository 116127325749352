import React from 'react'
import classNames from 'classnames';

import './BookConsultCard.scss';

const BookConsultCard = ({ maxWidth }) => {
    return (
        <div className={classNames("book-consult-wrapper", { "w-300": maxWidth })}>
            <div className="d-flex justify-content-around align-items-center flex-column flex-sm-row flex-lg-column">
                <div className="col-12 col-sm-6 col-md-5 col-lg-12">
                    <h2 className="consult-header">Need legal help?</h2>
                    <h3 className="consult-subheader">Work with a lawyer online</h3>
                    <div className="form-group">
                        <a className="btn btn-melon d-none d-md-block desktop" href={`${process.env.REACT_APP_SITE_URL}?newcase=true`}>
                            Book a Free Consult Now
                        </a>
                        <a href={`${process.env.REACT_APP_MOBILE_URL}/case/create`} className="btn btn-melon d-md-none mobile">Book a Free Consult Now</a>
                    </div>
                </div>
                <ul className="list-group col-12 col-sm-4 col-md-5 col-lg-12">
                    <li className="list-group-item d-flex align-items-center">
                        <img src={require("../../Images/Group4.svg")} alt="15 min FREE consult" />
                        <span>15 min FREE consult</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                        <img src={require("../../Images/Group3.svg")} alt="All devices: phone, tablet, laptop" />
                        <span>All devices: phone, tablet, laptop</span>
                    </li>
                    <li className="list-group-item d-flex align-items-center">
                        <img src={require("../../Images/Group2.svg")} alt="Anywhere in British Columbia" />
                        <span>Anywhere in British Columbia</span>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default BookConsultCard;