import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { axios } from "../../Core";

import { BlueHero, GetStartedBlockUS } from "../../Components";

import "./Policy.scss";

const Policy = () => {
	const [content, setContent] = useState(false);
	useEffect(() => {
		axios
			.post(`/api/v1/node/${process.env.REACT_APP_PRIVACY_NODE_ID}`)
			.then((res) => {
				console.log(res);
				setContent(res.data.data.body);
			});
	}, []);
	return (
		<div id="policy-page">
			<BlueHero type="policy" title='Qase Inc. ("Qase") Privacy Policy' />
			<section>
				<div className="q-py-25 q-py-40-md q-py-55-lg q-bg-whitesmoke">
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="col-12 col-md-10 col-lg-9 col-xl-8">
								<div className="q-mb-1_3">
									{!content ? (
										<div className="col-12 p-5 text-center">
											<FontAwesomeIcon
												icon={faSpinner}
												pulse
												size="6x"
												color="#220c89"
											/>
										</div>
									) : (
										<div
											dangerouslySetInnerHTML={{
												__html: content,
											}}
										></div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<GetStartedBlockUS />
		</div>
	);
};

export default Policy;
