import React from 'react';

import "./WhiteChartBlock.scss"

const WhiteChartBlock = ({ description, image_url }) => {
    return (
        <div className="card white-block-chart">
            <div className="card-body">
                <div className="media">
                    {image_url &&
                        <img className="img-fluid q-mr-15 align-self-center" src={image_url} alt="Qase" />
                    }
                    <div className="media-body align-self-center">
                        {description &&
                            <p className="mb-0" dangerouslySetInnerHTML={{ __html: description }}></p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhiteChartBlock;