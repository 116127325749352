import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import classNames from 'classnames';
import { Alert } from "react-bootstrap";

import { axios } from '../../Core'

import { GetStartedBlock, BlueHero, ContactBlock } from '../../Components';

import './Contact.scss';


const Contact = () => {
    const [isFormSubmited, setIsFormSubmited] = useState(false);
    const [show, setShow] = useState(false);
    const { register, handleSubmit, errors, reset } = useForm();
    const onSubmit = (data, e) => {
        setIsFormSubmited(true);
        let formData = new FormData();
        for (let [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }
        axios.post('/api/v1/marketing/send-contact', formData)
            .then(res => {
                setIsFormSubmited(false);
                setShow(true);
                reset();
                setTimeout(() => {
                    setShow(false)
                }, 2000);
            });
    };

    return (
        <div id="contact-page">
            <BlueHero />
            <div className="q-py-25 q-py-40-md q-py-55-lg q-bg-whitesmoke">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-6 mb-5">
                            <div className="q-mb-20 text-center">
                                <h2 className="q-font-48 q-color-midnightblue q-line-height1 q-ff-medium">Contact Qase</h2>
                                <p className="text-center mb-0">
                                    For general questions or business inquiries please fill out the form below.
                                    If you need to contact a lawyer,
                                    &nbsp;
                                    <a href={`${process.env.REACT_APP_SITE_URL}?newcase=true`} className="d-none d-md-inline-block">click here</a>
                                    <a href={`${process.env.REACT_APP_SITE_URL}?newcase=true`} className="d-md-none">click here</a>
                                    &nbsp;
                                    to book your free consult.
                                </p>
                            </div>
                            <Alert show={show} variant="primary">
                                <p className="mb-0">
                                    Your message has been sent.
                                </p>
                            </Alert>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <input className={classNames("form-control", { "is-invalid": errors.firstName })} autoComplete="chrome-off" type="text" placeholder="First Name" name="firstName" ref={register({ required: true })} />
                                    <div className="invalid-feedback">
                                        First name is required
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input className={classNames("form-control", { "is-invalid": errors.lastName })} autoComplete="chrome-off" type="text" placeholder="Last Name" name="lastName" ref={register({ required: true })} />
                                    <div className="invalid-feedback">
                                        Last name is required
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input className={classNames("form-control", { "is-invalid": errors.emailAddress })} autoComplete="chrome-off" type="email" placeholder="Email Address" name="emailAddress" ref={register({ required: true, pattern: /^\S+@\S+$/i })} />
                                    {errors.emailAddress &&
                                        <div className="invalid-feedback">
                                            {errors.emailAddress.type === "required" ? "Email is required" : "Please enter valid email"}
                                        </div>
                                    }

                                </div>
                                <div className="form-group">
                                    <input className={classNames("form-control", { "is-invalid": errors.phoneNumber })} autoComplete="chrome-off" type="text" placeholder="Phone" name="phoneNumber" ref={register({ required: true })} />
                                    {errors.phoneNumber &&
                                        <div className="invalid-feedback">
                                            Phone is required
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <textarea className={classNames("form-control", { "is-invalid": errors.message })} autoComplete="off" name="message" rows="5" cols="60" placeholder="Message" ref={register({ required: true })}></textarea>
                                    {errors.message &&
                                        <div className="invalid-feedback">
                                            Message is required
                                        </div>
                                    }
                                </div>
                                <button className="btn btn-melon" disabled={isFormSubmited}>Send Message</button>
                            </form>
                        </div>
                        <div className="col-12 col-md-4">
                            <ContactBlock
                                iconType="email"
                                title="Email Support"
                                description="For help with your account or the Qase platform email us at:"
                                contact="support@qase.ca"
                                isEmail={true}
                            />
                            <ContactBlock
                                iconType="phone"
                                title="Phone Support"
                                description="To connect with us directly for help with your account or the Qase platform call:"
                                contact="+1 (844) 435-7658"
                            />
                            <ContactBlock
                                iconType="question"
                                title="General Inquiries"
                                description="To learn more about our services reach out to us at:"
                                contact="info@qase.ca"
                                isEmail={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <GetStartedBlock />
        </div>
    )
};


export default Contact;