import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { axios } from '../../Core';
import { GetStartedBlock, BlueHero, LawyerBlock } from '../../Components'



const Lawyers = () => {

    const [isLoading, setLoading] = useState(true);

    const [lawyers, setLawyers] = useState([]);

    useEffect(() => {
        axios.post('/api/v1/marketing/lawyers')
            .then(res => {
                if (res.data.success) {
                    //console.log(res.data.data);
                    setLawyers(res.data.data);
                    setLoading(false);
                }
            })
    }, [])

    return (
        <div>
            <BlueHero
                title="View lawyers"
                descriptions={[
                    "At Qase we put lawyers through a rigorous 5 step screening process before they can use our service. It includes confirming practice eligibility, disciplinary history, level of experience, and customer focus. You’ll have peace of mind knowing your lawyer is looking out for your best interests."
                ]}
                image_src={require('../../Images/find_lawyer_0.png')}
            />

            <div className="q-py-50 q-py-md-80 q-py-110-lg q-bg-whitesmoke">
                <div className="container-fluid">
                    {isLoading ?
                        <div className="col-12 p-5 text-center">
                            <FontAwesomeIcon icon={faSpinner} pulse size="6x" color="#220c89" />
                        </div>
                        :
                        <div className="row">
                            {
                                lawyers.map(lawyer => {
                                    return (
                                        <div key={lawyer.uuid} className="col-sm-6 col-md-6 col-lg-4 qr-my-3">
                                            <LawyerBlock
                                                full_name={`${lawyer.first_name} ${lawyer.last_name}`}
                                                areas={lawyer.practice_areas}
                                                profile_picture={lawyer.profile_picture}
                                                link_url={`/lawyer/${lawyer.uuid}`}
                                                practice_start={lawyer.practice_start_date}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }

                </div>
            </div>

            <GetStartedBlock />
        </div>
    )
}

export default Lawyers;
