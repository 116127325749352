import React from 'react'

import "./LawyerInformationBlock.scss"

const LawyerInformationBlock = ({ title, description, image_url }) => {
    return (
        <div className="card lawyer-information-block">
            <div className="card-body">
                <div className="media justify-content-between">
                    <div className="media-body">
                        {title &&
                            <h3>{title}</h3>
                        }
                        {description &&
                            <p className="mb-0">{description}</p>
                        }
                    </div>
                    {image_url &&
                        <div className="text-center">
                            <img className="img-fluid" src={image_url} alt="Qase" />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default LawyerInformationBlock;