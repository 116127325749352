import React, { Fragment } from "react";
import { Route, Switch, Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
// import FullStory from 'react-fullstory';
//import ReactGA from 'react-ga';

import {
    Home,
    PracticeAreas,
    Lawyers,
    LawyerInformation,
    PracticeArea,
    LawyerDetail,
    Contact,
    Terms,
    Policy,
    QaseConvertTerms,
} from "./client/Pages";
import { Footer, WhiteNavbar } from "./client/Components/";
import { ScrollToTop } from "./client/Containers";

function App() {
    return (
        <Fragment>
            <ScrollToTop />
            <div className="App">
                <WhiteNavbar />
                <Switch>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                    <Route path="/practice-areas">
                        <PracticeAreas />
                    </Route>

                    <Route path="/practice-area/:id" component={PracticeArea} />

                    <Route path="/lawyers">
                        <Lawyers />
                    </Route>

                    <Route path="/lawyer/:id" component={LawyerDetail} />

                    <Route path="/lawyer-information">
                        <LawyerInformation />
                    </Route>

                    <Route path="/contact">
                        <Contact />
                    </Route>

                    <Route path="/terms">
                        <Terms />
                    </Route>

                    <Route path="/privacy_policy">
                        <Policy />
                    </Route>

                    <Route path="/qase-convert-terms">
                        <QaseConvertTerms />
                    </Route>
                </Switch>
                <Footer />

                <CookieConsent
                    location="bottom"
                    cookieName="cookie-agreed"
                    cookieValue={2}
                    style={{
                        background: "#0779bf",
                        alignItems: "center",
                        zIndex: "2147483647",
                    }}
                    disableButtonStyles={true}
                    buttonText="OK, I agree"
                    buttonClasses="agree-cookie-btn"
                    contentStyle={{ flex: "auto" }}
                    contentClasses="agree-cookie-content"
                    expires={100}
                >
                    <h2>
                        We use cookies on this site to enhance your user
                        experience.
                    </h2>
                    <p style={{ fontSize: "12px" }}>
                        By clicking any link on this page you are giving your
                        consent for us to set cookies. &nbsp;
                        <Link to="/privacy_policy" target="_blank">
                            More Info
                        </Link>
                    </p>
                </CookieConsent>
            </div>
        </Fragment>
    );
}

export default App;
